<template>
	<div class="-mt100 -approve">
		<div class="-edit-main">
			<el-form class="-form -flex -flex-ycenter" :model="form" :rules="rules" :ref="setting.formName" @submit.native="_formSubmit()" @submit.native.prevent>
				<div class="-w300 -ml30">
					<el-input v-model="form.checkCode" clearable placeholder="请输入提货码核销"></el-input>
				</div>
				<el-button type="primary" class="-button" @click="search()">核验</el-button>
			</el-form>
			<div class="-mt40 -border-top -pt20" v-if="view.id">
				<div class="-bold -ml15 -px15">核验信息</div>
				<div class="-mt20 -ml30 -flex">
					<div>
						<el-image :src="view.productCover"></el-image>
					</div>
					<div class="-ml15">
						<div class="-px16 -title">{{view.productTitle}}</div>
						<div class="-mt5"><text class='-cblack2'>{{view.productStandard}}</text><text class="-ml30 -cred -px20">x{{view.countBuy}}</text></div>
						<div class="-mt15"><text>{{view.addrName}}</text><text class='-ml20'>{{view.addrTel}}</text></div>
					</div>
				</div>
				<el-button type="primary" class="-ml30 -mt30" @click="confirm">确认提货</el-button>
			</div>
			<div class="-mt40 -border-top -pt20 -tac" v-if="isEmpty">
				<div class="-bold -ml15 -px15">您输入的提货码不存在请重新输入</div>
				
			</div>
		</div>
		
	</div>
	
</template>
<script>
import { reactive } from 'vue'
import _edit  from '@/assets/js/edit'

export default {
	props: [],
	emits: [ 'onClose' ],
	setup(props, context) {
		return {
			context,
			closeDrawer: ()=>{context.emit('onClose', 'add')},
			view: reactive({}),
			form: reactive({}),	
			options: reactive({}),
			setting: reactive({
				// moduleApi: '/Order/Order/checkCode',
				moduleName: '电子券码编号',
			}),
			rules: {
				itemNo: [
					{ required: true, message: '请输入电子券码', trigger: 'blur' },
				],
			},
			
		}
	},
	
	mounted: function(){
		this._load(this, _edit)
	},
	methods: {
		//校验核销码
		search: function(index){
			if(!this.form.checkCode) return this._msg('请输入提货码')
			if(this.form.checkCode == null || this.form.checkCode.length<6) return this._msg('请输入正确的提货码')
			this.view = {}
			this.$forceUpdate()
			this._post('/Order/Order/checkCode', this.form, (res)=>{
				this.view = res.data
				this.isEmpty = this.form.checkCode && !res.data
				this.$forceUpdate()
			});  
		},
		//确认提货
		confirm: function(){
			this._confirm('是否确认提货？',()=>{
				this._post('/Order/Order/confirm', { id: this.view.id }, (res)=>{
					this._msg('操作成功')
					this.form.checkCode = ''
					this.view = {}
					this.$forceUpdate()
				})
			})
		}
	}
}
</script>
<style scoped="scoped">
	.-edit-main{margin-left: 260px;width: 500px;}
	.-approve .el-input__inner{height: 60px}
	.-button{height: 42px;font-size: 16px;margin-left: 20px;width: 100px;}
	.-approve .el-image{height: 100px;width: 100px;}
	.-approve .-title{width: 350px;overflow: hidden;white-space: nowrap;text-overflow: ellipsis}
</style>


